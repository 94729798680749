.imgPoster {
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 10px;
  width: 100% !important;
}

.containerRadius {
  /* border-radius: 15px; */
  padding-top: 2px;
  overflow: hidden;
}

.top-movies-wrap { 
  padding-top: 50px;
}
/*.slick-slide img {
  border-radius: 10px !important;
}*/