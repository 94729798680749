.check {
  padding: 20px;
  margin: 20px;
}
.country-select {
  width: 40%;
  padding: .375rem .75rem;
  color: #495057;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 46px;
  border-radius: 3px 0 0 3px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
  border-right: none;
}

.hello {
  position: relative;
  display: flex;
  flex-wrap: wrap !important;
  align-items: center !important;
  text-align: center !important;
  margin: auto !important;
}

.otp-input > div {
  margin: 0 auto;
}
