.step-header p{
    color: #fff;
    font-size: 16px;
    padding: 0px 30px;
    line-height: 25px;
    font-weight: 500;
    text-align: center;    
    padding-top: 15px
}
.step-bar{
    background: #000;
    height: 6px;
    margin-bottom: 5px;
    border-radius: 14px;
}
.step-active-bar{
    background: #FED30A;
    height: 6px;
    margin-bottom: 5px;
    border-radius: 14px;
}
.step1-label { 
    color: #fff;
    font-size: 15px;
    font-weight: bold;
}
.step1-label span {
    color: red;
    font-size: 15px;
}
.step1 {
    margin: auto;
    width: 90%;
    padding: 10px;
}
.form-control {
    display: unset !important;
}
.film-heading {
    color: #fff;
}
.continue-btn {
    background-color: #FED30A;
    float: right;
    margin: 20px 0px;
}
.back-btn {
    background-color: #FED30A;
    float: left;
    margin: 20px 0px;
}
.rt-submit-err {
    color: red;
    font-size: 15px;
}
.react-date-picker {
    display: block !important;
}
.react-date-picker__wrapper {
    border: 1px solid #ced4da !important;
    height: 38px !important;
}
.react-date-picker__inputGroup__input{
    padding-left: 10px !important;
} 
.form-check-label {
    color: #000 !important;
}

