.popup-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.video-player-wrap {
  width: 50%;
  position: absolute;
  left: 25%;
  top: 70px;
}