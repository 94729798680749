/* .theo-primary-color,
.vjs-selected {
    color: #61dafb !important;
}

.theo-primary-background {
    color: #000000 !important;
    background-color: #61dafb !important;
} */

.theo-secondary-color {
    color: #ffffff !important;
}

.theo-secondary-background {
    color: #000000 !important;
    background-color: #ffffff !important;
}

.theo-tertiary-color {
    color: #000000 !important;
}

.theo-tertiary-background {
    color: #ffffff !important;
    background-color: #000000 !important;
}

.PlayerDiv {
    display: inline-block;
    zoom: 1;
    width: 100%;
    padding-bottom: 3vh;
}

.THEOplayer {
    width: 40%;
    margin: 0 auto;
}

/* .video-js.vjs-16-9 {
    padding-top: 28.12%;
    width: 50%;
} */