.center {
  display: block;
  margin: 0 auto 20px;
  height: 50px !important;
}
.about p {
  color: #ffffff;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  text-align: justify;
  letter-spacing: 1px;
}
/*.about .row > p {
  padding: 0px 30px;
}*/

.about h4 {
  color: #ffffff;
  font-size: 1.2rem;
}
.about ul li {
  color: #ffffff;
  font-size: 16px;
  padding: 0px 10px;
  line-height: 25px;
  font-weight: 500;
  text-align: justify;
}
.about {
  padding-bottom: 80px;
}
.address {
  /* margin-top: 20px; */
  margin-left: auto !important;
  margin-right: auto !important;
  /* padding: 10px 40px; */
}
.address h3 {
  font-size: 1.25rem;
  /*padding: 0px 15px;*/
  margin-bottom: 20px;
}

.contactDetails {
  margin-left: 30px;
}

.founder-content {
  padding: 0px 40px;
}

.founder-img {
  width: 80%;
  border-radius: 20px;
}

.founder-content p {
  padding: 25px 0px !important;
  text-align: justify;
}

@media (max-width: 767px) {
  .founder-img-wrap {
    text-align: center;
  }
  .address p {
    text-align: justify;
  }
}

@media (max-width: 1024px) {
  .founder-img {
    margin-top: 30px;
    width: 90%;
  }
}
