.cb-movie-details-wrap {
  position: relative;
}
.cb-movie-details-wrap:after {
  content: "";
  width: 100%;
  height: 200px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  bottom: 22px;
  left: 0;
  right: 0;
}

.cb-movie-details-wrap:before {
  content: "";
  width: 100%;
  height: 100px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: -125px;
  left: 0;
  right: 0;
  z-index: 1;
}

.generdetails {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}

.generdetailsContainer {
  margin-left: 0px;
  display: inline-block;
  align-self: flex-end;
  margin-bottom: 10px;
}

.md-movie-name {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0px;
}

.rating-details {
  background: url(../../../assets/img/star.png) no-repeat 5px center #ffffff;
  padding: 3px 10px 3px 25px;
  border-radius: 3px;
  font-size: 14px;
  color: #0b0119;
  font-weight: 500;
  display: inline-block;
}

.close-button {
  background: #ffc220;
  padding: 7px 20px;
  border-radius: 10px;
  display: inline-block;
  font-size: 14px;
  color: #321f00;
  font-family: "Conv_SanFranciscoText-Bold";
  text-transform: uppercase;
}

.video-link {
  cursor: pointer;
}
.overlayTr {
  margin-bottom: 10px;
}
.teaserImg {
  /* border: 1px solid #ffc220; */
  border-radius: 5px;
}

.movieright {
  width: 40%;
  float: right;
  padding-left: 30px;
}

.cast-crew::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

/*.cast-crew {
  height: 310px;
  overflow-x: hidden; 
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}*/

.cast-crew.open {
  height: auto;
}

.movieleft {
  width: 60%;
}

.profile_pic {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.crewdetails {
  padding-top: 10px;
}
.reviewdetails {
  background-color: #ffffff;
  padding: 0px 10px;
  padding-top: 7px;
  margin: 5px 0px;
  /* text-align:justify */
}

.reviewdetails h5 {
  color: black;
  font-size: 15px
}

.metadetails {
  font-size: 15px;
  color: #fff !important;
}

.hr {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
