@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
/*@font-face {
	font-family: "Brandon Grotesque";
  src: url("../fonts/brandon-grotesque.eot") format("embedded-opentype"), 
    url("../fonts/brandon-grotesque.woff") format("woff"),
		url("../fonts/brandon-grotesque.ttf") format("truetype"),
		url("../fonts/brandon-grotesque.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}*/
@font-face {
  font-family: 'Arial Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Arial Regular'), url('../fonts/arial.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Arial Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Arial Medium'), url('../fonts/ArialMdm.ttf') format('truetype');
    }
@font-face {
  font-family: 'Arial Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Arial Bold'), url('../fonts/arial-bld.ttf') format('truetype');
  }
/* @font-face {
	font-family: "Arial Bold";
  src: 
  url("../fonts/arial-bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
} */
* {
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

body {
  background: url(../img/color_bg.png) no-repeat top center / cover #000b24 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
  font-weight: normal !important;
  color: #fff !important;
  counter-reset: section;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700 !important;
  text-transform: none;
}
.h5,
h5 {
  font-size: 1rem;
}
.primary-color {
  color: #86c83d;
}
.secondary-color {
  color: #0d90e3;
}
.primary-bg {
  background-color: #86c83d;
}
.secondary-bg {
  background-color: #0d90e3;
}
.btn {
  border: none !important;
  border-radius: 3px !important;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  padding: 0.9rem 1.25rem 0.65rem !important;
  line-height: normal !important;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}
.btn-primary {
  background: #3538ba !important;
  color: #fff !important;
}
.btn-primary:hover {
  /* background: #86c83d !important; */
  color: #ddc278 !important;
}
.btn-goback{
  /* background: #3538ba !important; */
  color: #000 !important;
  background: url(../img/button-logo.png) no-repeat 15px center#ffffff !important;
}

.btn-signin {
  position: relative;
  top: -5px;
  height: 45px;
  line-height: 26px !important;
  border-radius: 0 !important;
}

/* Header */
header .navbar-dark .navbar-toggler {
  border: none;
}
.navbar svg {
  fill: #fff;
  width: 15px;
}
.navbar .nav-item {
  margin-right: 10px;
}
.navbar .nav-item .icon-search {
  padding-left: 0;
}
.navbar .nav-item:last-child {
  margin-right: 0;
}
.navbar .nav-item .btn {
  padding: 0.7rem 1.15rem 0.5rem !important;
}

header .container-fluid,
header .navbar {
  padding-right: 0;
}

.navbar-nav li a {
  color: #ffffff !important;
}

/* Landing Page */
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.navbar-nav .nav-link {
  color: #fff !important;
  font-size: 15px !important;
}
.navbar-nav .nav-link:hover {
  color: #0d90e3 !important;
}
.navbar-nav .active .nav-link::after {
  width: 20px;
  height: 2px;
  background: #ffc220;
  content: "";
  display: block;
  position: absolute;
  top: 45px;
}
.navbar-nav .active .nav-link {
  color: #ffc220 !important;
}
.signup-wrap {
  background: url(../img/login-bg.png) no-repeat center center /cover;
  padding: 150px 0;
}
.signup-wrap h2 {
  color: #f9f9f9;
  font-size: 2.2rem;
}
.signup-wrap-inner {
  width: 45%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.signup-wrap .content-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 40px;
  margin-top: 50px;
}
.signup-wrap .content-box .form-control {
  border-color: #000;
  border-radius: 0 3px 3px 0 !important;
}
.signup-wrap .content-box .form-control:focus {
  box-shadow: none;
  outline: none;
}
.signup-wrap .content-box .form-control,
.signup-wrap .content-box .btn {
  height: 46px;
}
.form-control {
  border-radius: 3px !important ;
}
.signup-wrap .content-box .btn {
  min-width: 49.5%;
  background: transparent !important;
  border: 1px #000000 solid !important;
  color: #000000 !important;
}
.content-box div .btn:first-child {
  margin-right: 5px;
}
.signup-wrap .signup-form {
  margin-bottom: 30px;
}
.signup-wrap .or {
  color: #b2b2b2;
}

.email-form {
  margin-top: 30px;
  margin-bottom: 30px;
}
.signup-via-social-links li {
  /* display: inline-block; */
  margin-right: 60px;
}
.signup-via-social-links li:last-child {
  margin-right: 0;
}
.signup-via-social-links li img {
  margin-right: 15px;
}
.signup-via-social-links li a {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.signup-via-social-links li button {
  border: 1px solid #000000 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 0px 0px !important;
    width: 100%;
    justify-content: center;
}
.facebook-image {
  width: 25px;
}

.kep-login-facebook {
  background-color: #fff !important;
  color: #000 !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
}
.mt-34px {
  margin-top: 34px;
}

.close-button {
  background: #ffc220;
  padding: 7px 20px;
  border-radius: 10px;
  display: inline-block;
  font-size: 14px;
  color: #321f00;

  text-transform: uppercase;
}

.entertainment-wrap {
  background: #86c83d;
  border-radius: 100% / 5%;
  position: relative;
  padding: 180px 0 80px;
  margin-top: -125px;
}
.entertainment-wrap h2 {
  color: #000;
}
/*.entertainment-wrap:before,
.entertainment-wrap:after {
    background: #86c83d;
    content: '';
    position: absolute;
    width:100%;
}
.entertainment-wrap:before {
    top:-30px;
    height:100px;
    transform: skewY(-4deg);
    -webkit-transform: skewY(-4deg);
}*/
.entertainment-wrap h3 {
  color: #000;
}

.banner-wrap {
  width: 100%;
  position: relative;
}

.slider-wrap:before {
  /* content: ""; */
  background: url(../img/banner-shadow.png) no-repeat center center;
  background-size: cover;
  /*background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0.75) 19%, rgba(0, 0, 0, 0.02) 39%, rgba(0, 0, 0, 0.15) 74%, rgba(0, 0, 0, 0.89) 94%);
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0.75) 19%, rgba(0, 0, 0, 0.02) 39%, rgba(0, 0, 0, 0.15) 74%, rgba(0, 0, 0, 0.89) 94%);
  background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0.75) 19%, rgba(0, 0, 0, 0.02) 39%, rgba(0, 0, 0, 0.15) 74%, rgba(0, 0, 0, 0.89) 94%);
  background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0.75) 19%, rgba(0, 0, 0, 0.02) 39%, rgba(0, 0, 0, 0.15) 74%, rgba(0, 0, 0, 0.89) 94%);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0.75) 19%, rgba(0, 0, 0, 0.02) 39%, rgba(0, 0, 0, 0.15) 74%, rgba(0, 0, 0, 0.89) 94%);*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
}
.banner-text {
  padding: 120px 0 0px;
  margin: 0 auto;
  width: 80%;
}
/*.banner-text h2 {
  font-size: 2.2rem;
}*/
.banner-text h2 span {
  color: #86c83d;
}
.banner-wrap .slick-dots {
  z-index: 1;
  text-align: left !important;
  bottom: 40px !important;
}

.features-wrap {
  padding: 100px 0 100px;
}
.features-col {
  border: 1px solid #333333;
  text-align: center;
  padding: 40px 100px;
  height: 100%;
  margin-left: -1px;
  margin-bottom: -1px;
}
.features-col h4 {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 20px;
}
.features-col h4 span {
  color: #86c83d;
}
.features-col p {
  color: #aaaaaa;
  margin: 0;
}

.banner-wrap + div {
  position: relative;
  z-index: 1;
}
.slick-dots {
  bottom: 40px;
  padding-left: 40px;
  text-align: left;
}
.slick-dots li {
  margin: 0;
  width: 15px;
  height: 15px;
}
.slick-dots li button:before {
  color: #fff !important;
  font-size: 8px !important;
  opacity: 1 !important;
}
.slick-dots li button:hover:before,
.slick-dots li.slick-active button:before {
  color: #86c83d !important;
}
.section-title {
  margin-bottom: 15px;
}
.section-title h2 {
  font-size: 1.1rem;
}

.section-title h2:before {
  content: "";
  width: 5px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background: #ED6D1D;
  margin-right: 15px;
}

.popular-movies-wrap .section-title h2:before {
  background: #56A537;
}

.short-film-wrap .section-title h2:before {
  background: #029EE1;
}
.section-title .btn {
  padding: 0.45rem 0.65rem 0.25rem !important;
}

.top-movies-wrap .section-title .btn-primary {
  background: #ED6D1D !important;
}

.popular-movies-wrap .section-title .btn-primary {
  background: #56A537 !important;
}

.short-film-wrap .section-title .btn-primary {
  background: #029EE1 !important;
}
.custom-row {
  margin: 0 -3px !important;
}
.movie-col {
  padding: 3px;
  flex: 0 0 20%;
  max-width: 20%;
  /* margin: 5px; */
}
.movie-col img {
  border-radius: 3px;
  height: 100%;
  /* object-fit: cover; */
  width: 100%;
}

.top-movies-list li img {
  height: 100%;
}
.popular-movies-list li img {
  height: 150px;
}
.submit-film-wrap {
  margin-top: 4rem;
}
/* .short-film-list .movie-col {
  flex: 0 0 11.11%;
  max-width: 11.11%;
} */
.submit-film-content {
  background: url(../img/bg_submitmovie.png) no-repeat;
  background-size: cover;
  border-radius: 5px;
  padding: 20px 40px;
}
.submit-film-text {
  margin-left: 35px;
}
.submit-film-text h2 {
  font-size: 1.45rem;
}
.submit-film-text p {
  font-weight: 500;
}
.latest-movies-content h3 {
  font-size: 1.45rem;
}

/* Footer Section Style Start */
.footer:before {
  content: "";
  width: 100%;
  height: 5px;
  display: block;
  background: url(../img/header-top-bg.png) no-repeat top center;
  background-size: 100%;
}
.footer h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: normal !important;
  text-transform: unset;
  margin-bottom: 22px;
}
.footer-bottom {
  padding: 80px 0;
  background: #000000;
}
.footer .footer-links li {
  /* margin-right: 35px; */
  margin-right: 20px;
}
.footer .footer-links li:last-child {
  margin-right: 0;
}
.footer-links li a {
  color: #939393;
  font-size: 14px !important;
}
.footer-links li a:hover {
  color: #fff;  
}
.social-links li {
  margin-right: 10px;
}
.social-links li:last-child {
  margin-right: 0;
}
.app-links a {
  display: inline-block;
}
.footer-bottom .copy-rights {
  margin-top: 1rem;
  font-size: 14px;
}

/* Inner page Header Section Style Start */
.header-inner {
  position: relative;
  margin-bottom: 3px;
  z-index: 2;
}

.header-inner:after {
  content: "";
  width: 100%;
  height: 5px;
  background: url(../img/header-top-bg.png) no-repeat top center;
  background-size: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.header-inner .navbar-light .navbar-nav .nav-link {
  color: #000 !important;
}

/* Home Page Style Start */
.slider-wrap {
  position: relative;
  height: 100%;
  margin-top: 65px;
}

/*.sliders {
  height: 380px !important;
}*/

.sub-title {
  font-size: 1.2rem;
  color: #f9f9f9;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 25px;
}

.read-more {
  color: #fff;
  font-weight: bold;
}

.upcoming-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.upcoming-list li a {
  display: block;
}

.upcoming-list li a img {
  width: 100%;
  max-height: 310px;
  object-fit: contain;
  border-radius: 3px !important;
}
.read-more-container {
  margin-bottom: 20px;
}

/* Upcoming movies page Style Start */
.page-title {
  font-size: 1.6rem;
  color: #fff;
  margin-bottom: 10px;
}

.breadcrum-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrum-nav li {
  display: inline-block;
  color: #fff;
  border-right: 1px #fff solid;
  padding: 0 10px;
}

.breadcrum-nav li:last-child {
  border-right: none;
  padding-right: 0;
}

.breadcrum-nav li.active {
  font-weight: 600;
}

.movie-filter-wrap {
  padding: 15px;
  border: 1px #424D65 solid;
  border-radius: 10px;
}

.filter-title {
  font-size: 1rem;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
}
.apply-button {
  margin: 30px 25px;
}
.btn-sorting {
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  padding: 6px 20px;
  background: transparent;
  border: 1px #fff solid;
  border-radius: 3px;
  margin: 10px 0px;
}

.btn-sorting:hover,
.btn-sorting.active {
  background: #0e0e0e;
  color: #ffffff;
}

.movie-filter-wrap select {
  border: none;
  border-bottom: 1px #ced4da solid;
  border-radius: 0;
  color: #0b0119;
}

.btn-apply {
  /*font-size: 16px;*/
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  background: #ffffff;
  padding: 7px 15px;
  border: none;
  border-radius: 3px;
}

.btn-apply:hover {
  background: #0d90e3;
  color: #fff;
}

.movies-list,
.all-movies-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.movies-list li,
.all-movies-list li {
  margin-bottom: 30px;
}

.movies-list li a,
.all-movies-list li a {
  display: block;
  position: relative;
}

.select-box {
  margin: 25px -15px;
}

.movies-list li a:after,
.all-movies-list li a:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC42NSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}

.movies-list li a img {
  width: 100%;
  max-height: 465px;
  object-fit: cover;
  border-radius: 5px;
}

.movie-details,
.movie-detail {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px 20px 20px 80px;
  z-index: 1;
  background: url(../img/play-white-icn.png) no-repeat 20px center;
}

.movie-name {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase !important;
}

.movie-date {
  font-size: 18px;
  color: #FCEB07;
  margin-bottom: 0;
}

.errorMsg {
  color: red;
}
.upcoming-movies-wrap .movieright a {
  display: none !important;
}
.upcoming-movies-wrap .cast-crew {
  margin-top: 20px;
}

/* Movies Page Style Start */
.all-movies-list li a img {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  border-radius: 3px;
}

.all-movies-list li a .movie-details {
  background: url(../img/play-yellow-icn.png) no-repeat 20px center;
}

.movie-info {
  font-size: 14px !important;
  color: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
  font-weight: 700;
}

.info {
  color: #FCEB07 !important;
}

.movie-rating {
  background: url(../img/star.png) no-repeat 5px center #ffffff;
  padding: 3px 10px 3px 25px;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  color: #0b0119;
  font-weight: 500;
}
.movie-hide {
  display: none;
}

.movie-image:hover .movie-hide {
  display: block;
}

.movie-image {
  border-radius: 10px;
  margin-right: 0px;
}
.movie-title {
  font-size: 25px;
  color: #000;
  /* margin-left: 20px; */
}
.movie-info {
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  /* margin-left: 20px; */
}
.movie-info-container {
  margin: 10px;
}
.ticket-block {
  background: url(../img/ticket-bg.png) no-repeat;
  background-size: 100%;
  min-height: 285px;
}
.dot {
  font-size: 10px;
  margin: 5px;
}
/* .movie-container {
  background: #ffffff;
  border-radius: 10px;
  padding: 25px;
} */
.movie-container h3 {
  color: #ffffff;
  padding-bottom: 20px;
  /* padding-left: 40px; */
}
.movie-details-container {
  background-color: #f3f3f3;
  display: flex !important;
  flex-wrap: nowrap !important;
  padding: 25px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.index_content {
  /* padding: 25px 0; */
}
.ticket_content .synopsis_detail {
  border-bottom: none;
}
.custom_width {
  /* width: 800px; */
  margin-left: auto;
  margin-right: auto;
}
.movie_date ul li {
  display: inline-block;
}
.movie_date ul {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 0px;
  border-radius: 10px;
}
.movie_date ul li p {
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 15px;
}
.movie_date ul li h6 {
  text-transform: uppercase;
  /* font-family: "Conv_SanFranciscoText-Semibold"; */
  font-size: 12px;
  margin-bottom: 0;
}
.movie_date ul li {
  cursor: pointer;
  padding: 15px 5px;
}
.movie_date ul li:hover,
.movie_date ul li.active {
  background: #0372B9;
  border-radius: 10px;
}
.ticket_content {
  background: #000B24;
}
.ticket_content .content_header {
  margin-top: 0;
} 
.tc-inner-content {
  padding: 30px;
}
.movie_date {
  margin-bottom: 10px;
}
.movie_date .row {
  margin-left: 0px;
  margin-right: 0px;
}
.show_time li {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 15px;
}
.show_time li p {
  margin-bottom: 0;
  background: #152345;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 10px;
  border-radius: 10px;
  line-height: 15px;
  text-align: center;
}
.show_time li p:hover {
  background: #0372B9;
  color: #ffffff;
}
.show_time li.disable {
  cursor: default;
}
.show_time li.disable p {
  background-color: #C41E3A;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
}
.show_time li.show_time--select p {
  background: #0372B9;
  color: #ffffff;
}
/* .select_time {
  margin-bottom: 30px;
  border-bottom: 1px solid #f4f4f4;
} */
.in-active-date {
  background: #152345;
  border-radius: 10px;
  margin-left: 10px;
  color: #fff;
}
.time_text {
  color: #c2c2c3;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.date_text {
  color: #c2c2c3;
  text-transform: uppercase;
}
.cb-ticket-time-info-wrap {
  padding-bottom: 60px;
}
.continue-button {
  font-size: 15px;
  background-color: transparent;
  border: 1px #ffffff solid;
  color: #ffffff;
  padding: 10px 25px;
  font-weight: bold;
  border-radius: 6px;
}
.button-container {
  border-top: 1px #2A3D67 solid;
  padding: 20px 30px;
}
.ticket-title {
  color: #f7d54f;
  font-size: 20px;
}
.ticket-info {
  padding: 35px 15px;
}
.running-time {
  text-transform: lowercase !important;
}
.ticket-value {
  font-size: 19px;
  color: #000;
  /* text-align: center; */
}
.ticket-time-info {
  padding-top: 15px;
  border-bottom: 1px #D2D2D2 solid;
  padding-bottom: 15px;
  margin: 0 !important;
}
.show_time {
  padding-left: 0;
}
.ticket-info-text {
  font-size: 15px;
  border-bottom: 1px #D2D2D2 solid;
  padding-bottom: 15px;
}
.ticket-value-title {
  font-size: 15px;
  color: #a2a2a2;
  /* text-align: center; */
}
.content-text {
  font-size: 12px;
  color: #000;
  padding: 10px;
}

.content-text-container {
  padding: 15px 0;
}

.content-text-container h3 {
  color: #000000;
  padding: 0;
}

.payment-container {
  background: #ffffff;
  border-radius: 10px;
  margin-left: 5px;
}

.content_header {
  text-transform: uppercase;
  font-size: 22px;
  /* font-family: "Conv_SanFranciscoText-Semibold"; */
  color: #000;
  margin-top: 15px;
  line-height: 24px;
  /* text-align: center!important; */
}
.content_header span {
  /* color: #ffc220; */
}

.ticket_detail {
  /* position: absolute; */
  /* top: 0; */
  padding: 0px 24px;
  width: 100%;
  color: #000;
}
.ticket_detail label {
  /* background-color: #000; */
  color: #000;
  /* border-radius: 4px; */
  text-transform: uppercase;
  font-size: 15px;
  /* padding: 2px 4px; */
  margin-bottom: 10px;
}
.ticket_detail h2 {
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 0;
  text-transform: uppercase;
  width: 78%;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* overflow: hidden; */
}
.ticket_detail ul li {
  display: inline-block;

  font-size: 12px;
  text-transform: uppercase;
  line-height: 13px;
  position: relative;
  margin-right: 5px;
}
.ticket_detail ul li:last-child::after {
  content: none;
}
.ticket_detail ul {
  margin-bottom: 0;
  /* margin-left: -40px; */
  margin-top: 5px;
}
.ticket_detail h3 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.ticket_detail h1 {
  font-size: 24px;

  line-height: 18px;
  margin-top: 0;
}
.tickets {
  position: relative;
  background: url(../img/payment-ticket-bg.png) no-repeat center center #ffffff;
  background-size: 100%;
  padding: 15px;
  margin-bottom: 20px;
}
.ticket_summary {
  /* background: #26273c; */
  padding: 15px 0px;
  margin-right: 16px;
  margin-left: 5px;
}
.ticket_image {
  position: relative;
  left: -6px;
  height: 160px !important;
}
.ticket_summary h3 {
  color: #ffffff;
  font-size: 22px;
  line-height: 20px;
}
.ticket_summary .form-control {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #ffffff;
  font-size: 14px;
  font-family: "Conv_SanFranciscoText-Medium";
}
.ticket_summary .btn-apply {
  /* background-color: #000; 
  color: #26273c;*/
  font-size: 12px;

  /* border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; 
  border: 1px solid #fff;*/
  padding: 7px 40px;
  margin-left: 5px;
}
.ticket_summary .form-control::placeholder {
  color: #393b4e;
  font-size: 14px;
}
.ticket_summary .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.ticket_summary label {
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
}
.ticket_summary .input-group {
  margin-bottom: 20px;
}
.summary_amount ul li p {
  font-size: 16px;
  font-family: "Conv_SanFranciscoText-Medium";
  margin-bottom: 10px;
  color: #ffffff;
}
.summary_amount ul {
  border-bottom: 1px solid #393b4e;
  padding-bottom: 10px;
  padding: 0;
}
.summary_amount ul:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.summary_amount ul:last-child li p {
  /* font-family: "Conv_SanFranciscoText-Semibold"; */
}
.payment-opt-txt {
  font-size: 13px;
  /* font-family: "Conv_SanFranciscoText-Semibold"; */
  margin: 12px 0;
  color: #000;
}
.payment-method {
  margin-top: 10px;
  padding: 0;
}
.razorpay {
  /* margin-right: 55px;  */
  /* border: solid;
  border-color: #555;
  border-width: 1px;
  border-radius: 5px; */
  list-style: none;
}
.payment_button_container {
  margin-top: 25px;
}
.razorpay_image_button {
  border: solid;
  border-color: #555;
  border-width: 1px;
  border-radius: 4px;
  padding: 10px 100px;
}
.razorpay_button {
  border: solid;
  border-color: #555;
  border-width: 1px;
  border-radius: 4px;
  padding: 5px 50px;
  width: 200px;
  height: 35px;
}
.razorpay_image {
  height: 22px;
  object-fit: contain;
  width: 100%;
}
.complete-order {
  background-color: #fff;
  border-radius: 5px;
}

.complete-order li {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  border: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 5px;
  /* margin-left: -30px; */
  margin-right: 5px;
}

.complete-order li span {
  font-size: 17px;
}
.completed-text {
  color: #000;
}
.movie-songs-modal .modal-content {
  background-color: #2c2d42 !important;
}
.movie-songs-modal .modal-title {
  color: #fff !important;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 10px;
}
.movie-songs-modal span {
  color: #fff !important;
}
.movie-songs-modal .modal-header,
.movie-songs-modal .modal-footer {
  justify-content: center !important;
  border: none !important;
}
.movie-songs-modal .modal-body {
  padding: 15px 30px;
}
.rating-button {
  margin-left: 12px;
  /* max-width: 13%; */
}
.btn-watchnow {
  font-size: 18px;
  font-weight: bold;
  color: #000 !important;
  background: url(../img/star.png) no-repeat 10px center #86c83d !important;
  padding-left: 35px !important;
  /* position: absolute; */
  left: 35%;
  bottom: 55px;
}
.watchnow {
  background: url(../img/play-icn.png) no-repeat 10px center #86c83d !important;
  margin-bottom: 1px !important;
}
.historyTicket_detail .list-group-item {
  background: url(../img/ticket_bg.png) center no-repeat;
  background-size: cover;
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 15px;
}
.historyTicket_detail .list-group-item:hover {
  background: url(../img/ticket_active.png) center no-repeat;
  background-size: cover;
}
.historyTicket_detail img {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 5px;
}
.historyTicket_detail h5 {
  color: #fff;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
}
.historyTicket_detail ul {
  margin-bottom: 0;
  margin-left: -40px;
}
.historyTicket_detail ul li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  vertical-align: middle;
}
.historyTicket_detail ul li:last-child {
  margin-right: 0;
}
.historyTicket_detail ul li::after {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 10px;
  background: #84849d;
  right: -9px;
  top: 4px;
}
.historyTicket_detail ul li:last-child::after {
  content: none;
}
.historyTicket_detail ul li p {
  margin-bottom: 0;
  font-size: 12px;
  color: #84849d;
}
.historyTicket_detail p {
  margin-bottom: 5px;
  font-size: 12px;
  color: #84849d;
}
.payment-status {
  padding: 2px 7px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  min-width: 80px;
  color: #ffffff !important;
}
.success {
  background: #4cad4a;
}
.danger {
  background: #ce3c3e;
}
.historyTicket_detail h3 {
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 0;
  font-family: "Conv_SanFranciscoText-Semibold";
}
.historyTicket_detail .list-group-item:hover h5,
.historyTicket_detail .list-group-item:hover h3 {
  color: #000;
}
.play-image {
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px !important;
  border-radius: 100% !important;
  object-fit: cover !important;
}
.movie-info-banner {
  height: 380px;
  position: relative;
}
/* .movie-info-banner:after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
} */
.movie-info-banner img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.movie-info-banner .btn-booknow {
  position: unset;
  margin-bottom: 10px;
  margin-left: -5px;
}
.movie-info-wrap {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.footer_menu {
  margin-bottom: 20px;
}
.footer_menu li {
  display: inline-block;
}
.footer_menu li a {
  color: #e3e3e5;
  font-size: 13px;
  line-height: 16px;
  margin: 0 10px;
  position: relative;
}
.footer_menu li a::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background: #e3e3e5;
  top: 4px;
  right: -14px;
}
.footer_menu li:first-child a {
  margin-left: 0px;
}
.footer_menu li:last-child a,
.footer_menu li:last-child a::after {
  margin-right: 40px;
  content: none;
}
.footer_bottom {
  padding: 30px 0 0;
}
.footer_btm p {
  margin: 0 10px;
}
/* .moviesContainer {
  height: 400px;
} */

/* profile_bg */
.profile_bg {
  /* background: url(../img/profile_banner.png) center no-repeat; */
  width: 100%;
  height: 110px;
}
.profile_image {
  width: 77px;
  height: 77px;
  border-radius: 100%;
  object-fit: cover;
  border: 2px solid #ffc220;
}
.profile_position {
  position: relative;
  height: 110px;
}
.profile_header {
  position: absolute;
  bottom: -65px;
  width: 100%;
  display: block;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
}
.profile_header h5 {
  font-size: 20px;
  color: #0b0119;
  margin-bottom: 0;
  margin-top: 15px;
}
.profile_header p {
  color: #68657f;
  margin-bottom: 0;
  font-size: 15px;
}
.btn.btn-primary-btn {
  border-radius: 30px;
  border: 1px solid #b8b6c3;
  padding: 7px 17px 6px;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 22px;
  float: right;
}
.profile_content {
  background: #181627;
  padding: 100px 0;
}
.sidebar_bg {
  background: #26273c;
}
.profileContent_detail {
  background-color: #1d1e33;
  padding: 20px !important;
}

/* sidenav */
.sidenav {
  margin-bottom: 0;
  padding: 0;
}
.sidenav li a {
  list-style-type: none;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}
.sidenav li {
  margin: 20px 5px;
  list-style-type: none;
}
.sidenav li a:hover {
  color: #ffc220;
}

.sidenav .active a {
  color: #ffc220;
}
.sidenav .active {
  position: relative;
}
.sidenav .active::before {
  content: "";
  width: 3px;
  height: 30px;
  background: #ffc220;
  display: block;
  position: absolute;
  left: -20px;
  top: -2px;
}

.myProfile_header h3 {
  font-size: 16px;
  color: #ffc220;
  line-height: 22px;
}
.myProfile_header p a {
  font-size: 12px;
  font-weight: 600;
  color: #ffc220;
  line-height: 16px;
  float: right;
  text-transform: uppercase;
}

.myProfile_header p a:hover {
  text-decoration: none;
}
.myProfile_header {
  padding-bottom: 20px;
  border-bottom: 1px solid #4a495f;
  margin-bottom: 20px;
}
.profile_upload {
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
}
.profile_upload .profile_pic {
  width: 147px;
  height: 147px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 0;
}
.profile_upload--icon {
  position: absolute;
  bottom: -16px;
  left: 38%;
}
.profile_upload--icon .upload_icon {
  width: 35px;
  height: 35px;
  margin: 0 auto;
}
.profile_upload--icon input {
  width: 36px;
  position: absolute;
  top: 0;
  opacity: 0;
}
.myprofile_content h5 {
  color: #ffc220;
  font-size: 13px;
  line-height: 16px;
}
.myprofile_content h3 {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  border-bottom: 1px solid rgba(255, 187, 20, 0.1);
  padding-bottom: 8px;
}

.btn-rate,
.btn-rate:hover {
  background: #0d90e3;
  border-radius: 5px;
  color: #0b0119;
  font-size: 14px;
  padding: 6px 30px;
  text-transform: uppercase;
  text-decoration: none;
}

.up-btn {
  border: none !important;
  margin-top: 15px;
}

/* history_summary */
.history_summary h3 {
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0;
}
.history_summary h4 {
  color: #fff;
  font-size: 13px;
  margin-bottom: 0;
}
.history_summary h4 span {
  color: #6e6e90;
}
.purchasehistory_detail .list-group-item {
  background: url(../img/ticket_active.png) center no-repeat;
  background-size: cover;
}
.purchasehistory_detail .list-group-item {
  margin-bottom: 20px;
}
.purchasehistory_detail .list-group-item h5,
.purchasehistory_detail .list-group-item h3 {
  color: #000;
}
.history_table .table td,
.history_table .table th {
  border: none;
}
.history_table .table th {
  color: #a8a7d0;
  font-size: 14px;
}
.history_table .table tr td {
  color: #fff;
  font-size: 14px;
  background-color: #27273c;
}
.history_table .table tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.history_table .table tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.history_price ul {
  margin-bottom: 0;
}
.history_price ul li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.history_price ul li p {
  color: #fff;
  font-size: 14px;
  font-family: "Conv_SanFranciscoText-Medium";
}
.history_price ul li p span {
  color: #8282a4;
}

.order-detail-wrap-header button.btn-booknow .btn-booknow:hover{
  background: transparent !important;
  font-size: 14px !important;
  color: #ffffff !important;
  border: 1px solid #4a495f;
  padding: 5px 20px !important;
  border-radius: 20px;
  position: unset;
}

.editForm .form-check-label {
  color: #ffffff !important;
}

@media (min-width: 1800px) {
  .container {
    max-width: 1690px !important;
  }
  .banner-wrap .slick-dots {
    padding-left: 270px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1400px !important;
  }
}

@media (max-width: 1200px) {
  body {
    font-size: 12px;
  }
  .header .navbar .nav-item {
    margin-right: 0;
  }
  .slick-dots {
    padding-left: 40px;
  }
  .tickets .ticket_detail {
    padding-left: 40px;
  }
}

@media (max-width: 991px) {
  .menu-wrap {
    background: #000000;
    padding: 15px;
    z-index: 9;
  }

  .header-inner .menu-wrap {
    background: transparent;
  }

  .header .navbar-toggler {
    background: transparent;
  }

  .country-select {
    width: 35%;
  }

  .signup-form select,
  .signup-form #phone {
    margin-bottom: 15px;
  }

  .signup-via-social-links li {
    margin-right: 0;
  }

  .features-col {
    padding: 40px 20px;
  }

  .submit-flim-text .mr-120 {
    margin: 0 auto;
  }

  .app-links a {
    margin-bottom: 10px;
    display: inline-block;
  }

  .slick-dots {
    bottom: 95px;
    padding-left: 30px;
  }
  .section-title h2,
  .submit-film-text h2,
  .latest-movies-content h3 {
    font-size: 1.3rem;
  }
  .footer h6 {
    margin-top: 30px;
  }
  .footer-bottom {
    padding: 60px 0;
  }
  .footer-bottom .copy-rights {
    margin-top: 1.5rem;
  }
  .app-links a:first-child {
    margin-right: 10px;
  }
  .razorpay_image_button {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .navbar .nav-item {
    margin: 0 0 10px;
  }
  header .header-logo {
    margin: 0 !important;
    width: 100%;
  }
  .navbar-toggler {
    float: right;
  }
  /* .movie-col img {
    height: auto;
  } */
  .slick-dots {
    padding-left: 16%;
  }
  .signup-wrap-inner {
    width: 95%;
  }
  .signup-wrap .content-box {
    padding: 25px 20px;
  }
  .signup-wrap .content-box .btn {
    min-width: auto;
    margin: 0 2px;
  }
  .country-select,
  .signup-form #phone {
    font-size: 0.75rem;
  }
  .otp-input > div {
    flex-wrap: wrap;
  }
  .otp-input > div input {
    font-size: 14px !important;
    width: 32px !important;
    height: 32px !important;
  }
  .tickets .ticket_detail {
    padding-left: 15px;
    margin-top: 20px;
  }
  .payment-container {
    margin-top: 20px;
  }
  .movie-filter-wrap .btn-apply {
    margin-top: 10px;
  }
  .page-title {
    font-size: 1.25rem;
    margin: 5px 0 0;
  }
  .signup-form .btn-actions {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 567px) {
  /* .banner-wrap .slider-wrap {
    height: 130px;
  } */
  .slick-dots {
    bottom: 20px;
    padding-left: 10px;
  }
  ul.custom-row {
    flex-wrap: nowrap;
    overflow-y: scroll;
  }
  .movie-col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 6px;
  }
  .short-film-list .movie-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 6px;
  }
  .submit-film-text {
    margin: 2rem 0;
  }
  .submit-film-content .video-play {
    margin: 0 !important;
  }
  .footer .footer-links li {
    margin-bottom: 15px;
  }
  .footer-top {
    background-size: cover;
    padding: 65px 0 40px;
    margin-top: 50px;
  }
}

.btn-booknow {
  background: url(../img/button-logo.png) no-repeat 15px center#ffffff !important;
  padding-left: 40px !important;
  margin-left: 0px !important;
  margin-top: 5px !important;
}
.about img {
  /* display: none; */
  margin-bottom: 40px;
}

.gstNo {
  padding-left: 40px !important;
}

/* Cinebazzar style start here - Submit Flim Wrap style start here */
.submit-movie-wrap {
  background: url(../img/shortflim_bg.png) no-repeat center center / cover;
  padding: 60px 0;
}

.cb-sub-title {
  font-size: 2.2rem;
  text-transform: uppercase;
}

.submit-movie-wrap p {
  max-width: 55%;
  margin: 0 auto 20px;
  font-size: 16px;
  font-weight: 600;
}

.btn-bordered {
  color: #ffffff !important;
  border: 1px #ffffff solid !important;
}

.btn-bordered:after {
  content: "";
  width: 10px;
  height: 10px;
  background: url(../img/button_arrow.png) no-repeat center center;
  display: inline-block;
  margin-left: 10px;
}

.join-today-wrap {
  background: url(../img/watchmovie_image.png) no-repeat center center / cover;
  padding: 90px 0;
}

.join-today-wrap p {
  margin: 0 auto 20px;
  font-size: 18px;
  font-weight: 600;
}

.teaser-trailer-wrap {
  position: relative;
}

.teaser-trailer-wrap img {
  width: 100%;
  /* height: 150px;
  object-fit: cover; */
}

.overlayTr {
  position: absolute;
  top: 30%;
  left: 45%;
}

.overlayTr img {
  width: auto;
  height: auto;
}

.genre-list {
  margin-left: -10px;
  margin-right: -10px;
}

.genre-name {
  background: #373854;
  color: #ffffff;
  font-weight: 600;
  padding: 7px 10px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 10px;
}

.rating-wrap {
  border: 1px #3D4E68 solid;
  padding: 20px;
  cursor: pointer;
}

.btn-add-rating {
  width: 100%;
  font-weight: 600;
  color: #ffffff !important;
  text-transform: uppercase;
  border: 1px #ffffff solid !important;
}

/* CB Movies page style start here */
.cb-inner-page-wraper {
  background: url(../img/inner-banner-bg.png) no-repeat top center /cover;
  min-height: calc(100vh - 61px);
}

/* Slider */
.slider {
  padding: 120px 0 30px;
}
.banner-wrap .slider {
  padding: 0;
}
.slider .carousel-control-next,
.slider .carousel-control-prev {
  width: auto;
  opacity: 1;
}
.slider .slick-dots {
  text-align: center;
  bottom: -40px;
}
.slider .slick-initialized .slick-slide {
  padding: 0 10px;
}
.slider .slick-initialized .slick-slide .slider_style img {
  border-radius: 5px;
}

.coming-soon-wrap p {
  font-size: 18px;
  font-weight: 600;
}

.breadcrum-wrap {
  margin-top: 100px;
}

@media (max-width: 992px) {
  .top-movies-list li img {
    height: 100%;
  }

  .cb-movie-details-wrap:after {
    bottom: 60px;
  }
}

@media (max-width: 768px) {
  .slider-wrap {
    height: auto;
  }

  .movie-col {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .movies-list li a img {
    max-height: 100%;
  }

  .upcoming-list li a img {
    max-height: 100%;
  }

  .cb-movie-details-wrap:after {
    bottom: 0;
  }
}

.rt-ml-5 {
  margin-left: 5px;
}

/* 404 page not found page style start here */
.page-not-found-wrap {
  min-height: calc(100vh - 353px);
  padding: 120px 0;
}

.page-not-found-wrap h1 {
  font-size: 140px;
}

.page-not-found-wrap h3 {
  font-size: 50px;
  margin-bottom: 30px;
}

.react-autosuggest__input {
  width: 300px !important;
  height: 35px !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
  margin-top: 15px !important;
}

.react-autosuggest__suggestions-container {
  width: 100%;
  margin-top: 10px;
  padding-left: 5px;
  padding: 7px 5px;
}

.subscription-container {
  background-color: #ffc220;
  border-radius: 20px;
  padding: 7px;
}
.subscription-title {
  font-size: 30px;
  color: #000;
}
.subscription-description {
  font-size: 17px;
  color: #000;
}
.subscription-subvalue {
  font-size: 40px;
  color: #000;
  padding-top: 10px;
}
.subscription-uom {
  font-size: 20px;
  color: #000;
  text-transform: uppercase;
}
.subscription{
   border-width: 2px;
   border-style: dotted;
   border-color: #000;
   border-radius: 10px;
   margin: 5px;
   padding: 15px;
}

.serchMovieIcon {
  display: flex !important;
}
.searchImgSet {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  /* margin-top: 15px; */
  cursor: pointer;
  background-color:#c2c2c3;
  padding: 10px;
  border-radius: 5px;
}

.MuiInputBase-root {
  background-color: #ffffff !important;
  height: 40px !important;
  top: -16px !important;
  right: -4px;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 2.5px 4px !important;
  border-color: #fff;
}

.collection-container {
   margin-bottom: 30px;
}

.description {
  line-height: 22px;
  text-align: justify;
  font-size: 14px;
}

/* responsive style */
.top-movies-list .movie-col, .popular-movies-list .movie-col {
  flex: 0 0 25%;
  margin: 0px;
  padding: 8px;
}
.popular-movies-list li img {
  height: 100%;
}
@media only screen and (max-width:576px) {
  .this-week-movies-list .col-md-2, .popular-movies-list .movie-col {
    flex: 0 0 50% !important;
    margin: 0px;
    padding: 8px;
  }
  /* upcoming movies */
  .movies-list .col-md-3 {
    flex: 0 0 50% !important;
    margin: 0px;
    padding: 8px;
  }
}
@media only screen and (min-width:576px) and (max-width:768px) {
  .top-movies-list .movie-col, .popular-movies-list .movie-col {
    flex: 0 0 50% !important;
    margin: 0px;
    padding: 8px;
  }
  .this-week-movies-list .col-md-2, .popular-movies-list .movie-col {
    flex: 0 0 50% !important;
    margin: 0px;
    padding: 8px;
    max-width: 50%;
  }
  /* upcoming movies */
  .movies-list .col-md-3 {
    flex: 0 0 33.3% !important;
    margin: 0px;
    padding: 8px;
    max-width: 33.3%;
  }
  .upcoming-list .col-md-2 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
}
@media only screen and (min-width:768px) {
  .this-week-movies-list .col-md-2 {
    flex: 0 0 20% !important;
    margin: 0px;
    padding: 8px;
    max-width: 20%;
  }
  .upcoming-list .col-md-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }

}
@media only screen and (min-width:768px) and (max-width:991px) {
  .movie-col {
    flex: 0 0 33.3% !important;
    max-width: 33.3% !important;
}
}
.app-links a {
  margin-right: 10px;
}
.movie-filter-col {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.all-movies-list li a img {
  max-height: 100%;
}

.slider_style {
  position: relative;
}
.slider_book {
  position: absolute;
  bottom: 0;
  padding-left: 5px;
  z-index: 1;
  padding-right: 5px;
  background-color: black;
  opacity: 0.7;
  width: 100%;
}
.slider_book h5 {
    color: #fff;
    font-family: "Arial Bold";
    font-size: 18px;
    font-weight: normal !important;
    line-height: 15px;
    padding-top: 5px;     
    overflow: hidden;
    /* padding-bottom: 4px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
}
.slider_book h6 span {
  color: #FCEB07;
  font-family: "Arial Regular";
  font-weight: normal !important;
  border-radius: 10px;
  font-size: 11px;
  border: 1px solid #fff;
  display: inline-block;
  padding: 3px 7px;
  line-height: 15px;
  text-transform: uppercase;
  margin-right: 5px;
}
.streaming_now_button {
  position: absolute;
  top: 0;
  padding: 5px;
  z-index: 1;
  padding-right: 10px;
  background-color: black;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  height: 30px;
}
.streaming_now_button h5 {
  color: #F9C368;
  font-family: "Arial Medium";
  font-weight: normal !important;
  text-transform: uppercase;
  font-size: 15px;
}
.collect h6 span {
  color: #FCEB07;
  font-family: "Arial Bold";
  border-radius: 10px;
  font-size: 11px;
  border: 1px solid #fff;
  display: inline-block;
  padding: 5px;
  line-height: 10px;
  text-transform: uppercase;
  margin-right: 10px;
}
.collect h5 {
  color: #fff;
  font-family: "Arial Bold";
  font-size: 18px;
  line-height: 10px;
  padding-top: 10px;    
  overflow: hidden;
  padding-bottom: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: bold;
}
.movie-info-banner .img-fluid {
  max-width: 85% !important;
  box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.9);
}
.gener {
  color: #fff;
  font-family: 'Arial Bold';
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #fff;
  display: inline-block;
  padding: 3px 10px;
  line-height: 15px;
  text-transform: uppercase;
  margin: 15px 10px;
  font-weight: bold;
}

.kep-login-facebook.metro {
  width: 100% !important;
  margin-bottom: 15px !important;
  border: 1px solid #000000 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.24) 0px 0px 0px 0px !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
.facebook-image {
  margin-right: 15px !important;
}
.kep-login-facebook {
  padding: 10px !important;
}