.movie-songs-modal .form-group {
    margin: 30px 0 0;
  }
  
  .movie-songs-modal .form-group label {
    color: #ffffff;
  }
  
  .modal-footer-wrap {
    padding-bottom: 20px;
  }
  
  .review-list {
    margin: 0;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 10px;
    list-style-type: none;
  }
  
  .review-list li {
    background: #ffffff;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .review-list-header {
    margin-bottom: 10px;
  }
  
  .user-img {
    width: 40px;
    height: 40px;
  }
  
  .reviewer-name {
    font-family: "Conv_SanFranciscoText-Semibold";
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
    color: black;
  }
  
  .review-date {
    font-family: "Conv_SanFranciscoText-Regular";
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
    color: #000;
  }
  
  .review-txt {
    font-size: 16px;
    font-family: "Conv_SanFranciscoText-Regular";
    line-height: 20px;
    margin-bottom: 0;
    color: #000;
  }
  
  .user-ratings {
    font-size: 16px;
    font-family: "Conv_SanFranciscoText-Bold";
    line-height: 20px;
    margin-bottom: 0;
  }
  
  .star-img {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 15px solid #ffc220;
    position: relative;
    display: inline-block;
    margin-left: 5px;
  }
  
  .star-img:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 14px solid #ffc220;
    position: absolute;
    content: "";
    top: 5px;
    left: -7px;
  }
  .reviewContent {
    padding: 0px 35px;
  }

  .reviewTitle {
    font-size: 20px;
  }
  