.producer-content-wrap {
    background: #1d1e33;
    padding: 20px;
}

.channel-list {
    margin-bottom: 0;
    list-style: none;
}

.channel-list li a {
    display: block;
    text-decoration: none;
}

.pc-img {
    width: 100%;
    min-height: 330px;
    object-fit: cover;
    border-radius: 10px;
}

.pc-title {
    font-size: 16px;
    font-family: "Conv_SanFranciscoText-Regular";
    color: #ffffff;
    line-height: 22px;
}